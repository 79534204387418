<template>
  <label
    :for="id"
    :class="labelVisible === false && 'sr-only'"
    class="ui-text-base ui-font-bold"
  >
    {{ label }}
  </label>
  <div class="w-full">
    <div class="relative">
      <select
        :id="id"
        :disabled="disabled"
        :error="error"
        :warning="warning"
        :success="success"
        v-model="selectValue"
        :class="[selectClasses, sizeClasses[size]]"
        class="cursor-pointer relative ui-my-[6px] ui-appearance-none ui-font-normal ui-border-sm ui-rounded-sm ui-text-base ui-leading-base ui-w-full ui-px-[10px] ui-pr-[60px] ui-text-charcoal-default outline-none hover:ui-ring-[6px] hover:ui-ring-blue-lighter focus:ui-ring-[6px] focus:ui-ring-blue-lighter disabled:ui-ring-0 disabled:ui-bg-grey-lighter disabled:ui-border-grey-lighter placeholder:ui-text-charcoal-lighter"
        @change="handleSelectChange"
        data-test-id="selectInput"
      >
        <option value="" selected disabled hidden>
          {{ placeholder }}
        </option>
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :data-test-id="`selectOption-${option.value}`"
        >
          {{ option.label }}
        </option>
      </select>
      <div
        class="arrow bg-contain bg-no-repeat size-[16px] absolute top-1/2 right-[8px] -translate-y-1/2 pointer-events-none"
      />
    </div>
    <Text
      data-test-id="fieldMessage"
      v-if="(error || warning || success) && messageText"
      size="caption"
      weight="semi"
      :class="[
        'ui-text-charcoal-lighter',
        error && 'ui-text-red-dark',
        warning && 'ui-text-orange-dark',
        success && 'ui-text-green-dark',
        disabled && '!ui-text-charcoal-lighter',
      ]"
    >
      {{ messageText }}
    </Text>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineEmits, defineProps, ref, toRefs } from "vue";
import type { SelectProps } from "./types";
import Text from "../../Typography/Text/Text.vue";

const props = withDefaults(defineProps<SelectProps>(), {
  size: "default",
  modelValue: "",
  labelVisible: true,
});

const {
  id,
  label,
  placeholder,
  options,
  size,
  labelVisible,
  error,
  warning,
  success,
  disabled,
} = toRefs(props);

const emit = defineEmits<{
  (event: "update:modelValue", value: string): void;
}>();

const model = ref(props.modelValue);
const selectValue = computed({
  get: () => model.value,
  set: (value) => {
    emit("update:modelValue", value);
    model.value = value;
  },
});

const handleSelectChange = () => {
  emit("update:modelValue", selectValue.value);
};

const sizeClasses = {
  ["sm"]: "ui-h-md",
  ["default"]: "ui-h-lg",
  ["lg"]: "ui-h-xl",
};

const selectClasses = computed(() => {
  return [
    error.value && "ui-border-red-default",
    warning.value && "ui-border-orange-default",
    success.value && "ui-border-green-default",
    !error.value &&
      !warning.value &&
      !success.value &&
      !disabled.value &&
      "ui-border-grey-default",
  ];
});
</script>

<style scoped>
.arrow {
  background-image: url("./assets/arrow.svg");
}
</style>
